// Page type
import { PageType } from '../interfaces/page_type.interface'

// contents
import { activityType } from '../content/activityType'
import { activityTypeInCity } from '../content/activityTypeInCity'
import { activityTypeInDepartment } from '../content/activityTypeInDepartment'
import { activityTypeInRegion } from '../content/activityTypeInRegion'
import { category } from '../content/category'
import { categoryInCity } from '../content/categoryInCity'
import { categoryInDepartment } from '../content/categoryInDepartment'
import { categoryInRegion } from '../content/categoryInRegion'
import { city } from '../content/city'
import { department } from '../content/department'
import { haTag } from '../content/haTag'
import { region } from '../content/region'

import { angers } from '../content/custom/angers'
import { aveclespompiers } from '../content/custom/aveclespompiers'
import { comitesDesFetes } from '../content/custom/comites-des-fetes'
import { feteMusique } from '../content/custom/fete-musique'
import { givingTuesday } from '../content/custom/giving-tuesday'
import { halloween } from '../content/custom/halloween'
import { jidddf } from '../content/custom/jidddf'
import { lafrancesengage } from '../content/custom/lafrancesengage'
import { leKremlinBicetre } from '../content/custom/le--kremlin-bicetre'
import { medecinsdumonde } from '../content/custom/medecinsdumonde'
import { merignac } from '../content/custom/merignac'
import { monsEnBaroeul } from '../content/custom/mons-en-baroeul'
import { movember } from '../content/custom/movember'
import { octobreRose } from '../content/custom/octobre-rose'
import { paques } from '../content/custom/paques'
import { podcasthon } from '../content/custom/podcasthon'
import { rencontresPourLaPlanete } from '../content/custom/rencontres-pour-la-planete'
import { rugby } from '../content/custom/rugby'
import { saintPatrick } from '../content/custom/saint-patrick'
import { sapeursPompiers } from '../content/custom/sapeurs-pompiers'
import { saumur } from '../content/custom/saumur'
import { seineSaintDenis } from '../content/custom/seine-saint-denis'
import { seismes } from '../content/custom/seismes'
import { socialGoodWeek } from '../content/custom/social-good-week'
import { solidariteUkraine } from '../content/custom/solidarite--ukraine'
import { sudouest } from '../content/custom/sudouest'
import { telethon } from '../content/custom/telethon'
import { vacancesEte } from '../content/custom/vacances-ete'
import { vacancesPaques } from '../content/custom/vacances-paques'
import type { Filters } from '~/domains/search/interfaces/filters.interface'
import type { ContentPage } from '../interfaces/content.interface'

export const getPageTypeByFilters = (filters: any): PageType => {

  // ------
  // city - activity
  if (filters.place_region && filters.place_department && filters.place_city && filters.activity_type) {
    return PageType.ACTIVITY_TYPE_IN_CITY
  }
  // city - category
  if (filters.place_region && filters.place_department && filters.place_city && filters.category_tags) {
    return PageType.CATEGORY_IN_CITY
  }
  // city
  if (filters.place_region && filters.place_department && filters.place_city) {
    return PageType.CITY
  }

  // ------
  // department - activity
  if (filters.place_region && filters.place_department && filters.activity_type) {
    return PageType.ACTIVITY_TYPE_IN_DEPARTMENT
  }
  // department - category
  if (filters.place_region && filters.place_department && filters.category_tags) {
    return PageType.CATEGORY_IN_DEPARTMENT
  }
  // department
  if (filters.place_region && filters.place_department) {
    return PageType.DEPARTMENT
  }

  // ------
  // region - activity
  if (filters.place_region && filters.activity_type) {
    return PageType.ACTIVITY_TYPE_IN_REGION
  }
  // region - category
  if (filters.place_region && filters.category_tags) {
    return PageType.CATEGORY_IN_REGION
  }
  // region
  if (filters.place_region) {
    return PageType.REGION
  }

  // ------
  // activity
  if (filters.activity_type) {
    return PageType.ACTIVITY_TYPE
  }
  // category
  if (filters.category_tags) {
    return PageType.CATEGORY
  }
  // event
  if (filters.ha_tags) {
    return PageType.HA_TAG
  }

}

export const genericContent = {
  activityType: activityType,
  activityTypeInCity: activityTypeInCity,
  activityTypeInDepartment: activityTypeInDepartment,
  activityTypeInRegion: activityTypeInRegion,
  category: category,
  categoryInCity: categoryInCity,
  categoryInDepartment: categoryInDepartment,
  categoryInRegion: categoryInRegion,
  city: city,
  department: department,
  haTag: haTag,
  region: region,
}

export const thematicCustomContents = {
  angers: angers,
  aveclespompiers: aveclespompiers,
  'comites-des-fetes': comitesDesFetes,
  'fete-musique': feteMusique,
  'giving-tuesday': givingTuesday,
  halloween: halloween,
  jidddf: jidddf,
  lafrancesengage: lafrancesengage,
  'le--kremlin-bicetre': leKremlinBicetre,
  medecinsdumonde: medecinsdumonde,
  merignac: merignac,
  'mons-en-baroeul': monsEnBaroeul,
  movember: movember,
  'octobre-rose': octobreRose,
  paques: paques,
  podcasthon: podcasthon,
  'rencontres-pour-la-planete': rencontresPourLaPlanete,
  rugby: rugby,
  'saint-patrick': saintPatrick,
  'sapeurs-pompiers': sapeursPompiers,
  saumur: saumur,
  'seine-saint-denis': seineSaintDenis,
  seismes: seismes,
  'social-good-week': socialGoodWeek,
  'solidarite--ukraine': solidariteUkraine,
  sudouest: sudouest,
  telethon: telethon,
  'vacances-ete': vacancesEte,
  'vacances-paques': vacancesPaques
}

const handlerCallContent = {
  ...genericContent,
  ...thematicCustomContents
}

export function getPage (pageType: string, filters: Filters) {
  let page: ContentPage
  let isCustomPage = false

  switch (pageType) {
    case PageType.HA_TAG:
      page = handlerCallContent[filters.ha_tags as string]
      break

    case PageType.ACTIVITY_TYPE:
      page = handlerCallContent[filters.activity_type as string]
      break

    case PageType.CATEGORY:
      page = handlerCallContent[filters.category_tags as string]
      break

    case PageType.CITY:
      page = handlerCallContent[filters.place_city as string]
      break

    case PageType.DEPARTMENT:
      page = handlerCallContent[filters.place_department as string]
      break

    case PageType.REGION:
      page = handlerCallContent[filters.place_region as string]
      break
  }

  if (page) {
    isCustomPage = true
  } else {
    page = handlerCallContent[pageType]
  }

  return {
    page: structuredClone(page),
    isCustomPage
  }
}
