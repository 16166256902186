
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { FILTER_DATE_OPTIONS_KEY } from '~/domains/search/interfaces/filters.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../interfaces/content.interface'

export const region: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: 'Les activités et associations {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>'
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: 'Associations {atRegion}{region} | Annuaire activités et projets',
        description: 'Trouvez des projets ou activités associatives auxquels participer {atRegion}{region}, et rejoignez des centaines de clubs, amicales, compagnies référencés sur la plateforme HelloAsso.'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}',
            name: '{region}'
          }
        ]
      }
    }
  },

  // Que faire en <région>
  // urls parameters for this page are meant to be:
  // - place_region
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    id: 'whatToDoInRegion',
    slug: 'place_region',
    text: {
      title: 'Agenda : que faire {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu en ce moment'
    },
    carousels: [
      // Que faire en <région> cette semaine ?
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{region} : que faire cette semaine ?',
          showAllQueryParams: '?tab=activites&place_region={region}&date=cette+semaine'
        },
        id: 'whatToDoThisWeekInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK
            },
          }
        }
      },
      // Que faire en <région> la semaine prochaine ?
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{region} : que faire la semaine prochaine ?',
          showAllQueryParams: '?tab=activites&place_region={region}&date=la+semaine+prochaine',
        },
        id: 'whatToNextWeekInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_WEEK
            },
          }
        }
      },
      // Que faire en <région> le mois prochain ?
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{region} : que faire le mois prochain ?',
          showAllQueryParams: '?tab=activites&place_region={region}&date=le+mois+prochain',
        },
        id: 'whatToDoNextMonthInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_MONTH
            },
          }
        }
      },
      // Toutes les activités
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title:  '{region} : toutes les activités',
          showAllQueryParams: '?tab=activites&place_region={region}'
        },
        id: 'allActivitiesInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
            },
          }
        }
      }
    ]
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Associations en <région>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    id: 'organizationsInRegion',
    text: {
      title: 'Annuaire : les associations {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Bénévolat, don, engagement rejoignez des centaines de clubs, amicales, compagnies.',
      showAllQueryParams: '?tab=associations&place_region={region}'
    },
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association {fromRegion}<span class="Color-Primary">{region}</span> ?'
    },
    slug: 'place_region'
  },
  // Projets en <région>
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    id: 'projectsInRegion',
    slug: 'place_region',
    text: {
      title: 'Les projets de financement participatif {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Bénévolat, don, engagement rejoignez des centaines de clubs, amicales, compagnies.'
    },
    carousels: [
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Projets de financement participatif {atRegion}{region}',
          showAllQueryParams: '?tab=projets&place_region={region}'
        },
        id: 'allProjectsInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.PROJECTS,
          params: {
            hitsPerPage: 50,
            filters: {},
          }
        }
      }
    ]
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP,
    showDepartments: true
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
