import type { HASearchResponse } from '~/domains/search/interfaces/search.interface'
import type { HaMultipleQueriesQueryUI } from '~/domains/search/interfaces/query.interface'
import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons'

export type ContentPage = ContentBlock[]

export interface ContentBlock {
  component?: BLOCK_COMPONENT
  id?: string
  defaultText?: ComponentContent
  text?: ComponentContent
  useUrlFilters?: boolean
  slug?: string
  searchable?: HaMultipleQueriesQueryUI
  result?: HASearchResponse
  showCities?: boolean
  showDepartments?: boolean
  carousels?: ContentBlock[]
  seoData?: SeoData
  itemNumber?: number
}

export interface ComponentContent {
  [key: string]: string
}

export interface SeoData {
  meta?: Meta
  microdata?: SeoMicrodata
}

export interface Meta {
  title?: string
  description?: string
}

export interface SeoMicrodata {
  content?: SeoMicrodataContent[]
  parentCategory?: SeoMicrodataContent
}

export interface SeoMicrodataContent {
  url?: string
  name?: string
}

export interface Location {
  region?: string
  department?: string
  city?: string
}

export enum BLOCK_COMPONENT {
  AMOUNT_CARD = 'AmountCard',
  CITIES = 'Cities',
  HERO = 'Hero',
  HOW_IT_WORKS = 'HowItWorks',
  SECTION_TITLE = 'SectionTitle',
  SEO_CONTENT_1 = 'SeoContent1',
  SEO_CONTENT_2 = 'SeoContent2',
  SEO_CONTENT_3 = 'SeoContent3',
  SIGN_UP = 'SignUp',
  SITEMAP = 'Sitemap',
  SOCIALS = 'Socials',
  FAQ = 'Faq',
  HIT_LIST = 'HitList',
  CAROUSEL = 'Carousel',
  CAROUSEL_GROUP = 'CarouselGroup',
}

export interface ContentNetworks {
  [key: string]: ContentNetwork
}

export interface ContentNetwork {
  name: string
  link: string
  icon: IconDefinition
  id: string
}
